<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="1100.000000pt"
    height="1100.000000pt"
    viewBox="0 0 1100.000000 1100.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,1100.000000) scale(0.100000,-0.100000)"
      stroke="none"
    >
      <path
        d="M500 5500 l0 -5000 5000 0 5000 0 0 5000 0 5000 -5000 0 -5000 0 0
-5000z m9600 0 l0 -4600 -4600 0 -4600 0 0 4600 0 4600 4600 0 4600 0 0 -4600z"
      />
      <path
        d="M1500 5500 l0 -4000 4000 0 4000 0 0 4000 0 4000 -4000 0 -4000 0 0
-4000z m5175 1019 c160 -226 295 -415 301 -420 7 -6 105 127 301 409 l290 417
365 3 364 2 21 -43 21 -44 -464 -642 c-255 -353 -464 -647 -464 -652 0 -5 221
-315 490 -688 270 -374 493 -686 496 -694 3 -8 -4 -33 -16 -56 l-20 -41 -383
0 -382 1 -315 454 c-244 354 -317 452 -326 442 -6 -6 -147 -211 -313 -454
l-302 -443 -379 0 -380 0 -20 40 c-11 21 -20 44 -20 49 0 6 221 315 491 687
384 529 489 680 482 694 -4 10 -215 306 -467 659 l-459 643 21 44 22 44 377 0
378 0 290 -411z m-2448 391 c263 -35 489 -106 660 -206 l42 -24 -93 -228 c-51
-125 -95 -234 -98 -243 -5 -14 -23 -8 -113 36 -127 63 -251 105 -395 136 -147
31 -390 31 -502 1 -177 -48 -262 -128 -275 -258 -11 -109 46 -187 184 -251 48
-22 188 -63 406 -118 377 -95 455 -119 582 -181 221 -107 357 -264 401 -460
25 -116 16 -313 -20 -419 -73 -213 -216 -366 -451 -481 -180 -87 -339 -122
-600 -131 -347 -12 -694 53 -972 182 -91 42 -223 122 -223 136 0 11 200 460
211 473 4 4 27 -5 51 -22 75 -52 247 -132 368 -173 185 -62 330 -82 535 -76
222 7 312 31 400 109 53 46 78 102 78 173 0 170 -115 238 -589 350 -366 85
-549 152 -707 258 -140 94 -241 234 -278 387 -23 91 -18 300 9 393 97 339 391
563 828 631 107 17 451 20 561 6z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'AppLogo',
}
</script>

<style scoped>

</style>
