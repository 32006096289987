<template>
  <b-nav-item-dropdown
    ref="dropdown"
    class="dropdown-impersonation mr-25"
    menu-class="dropdown-menu-media p-0"
    right
  >
    <template #button-content>
      <feather-icon
        icon="SettingsIcon"
        size="18"
      />
    </template>
    <b-card class="m-0 admin-menu">
      <div class="tw-pb-2 tw-text-white">
        Tools & Settings
      </div>
      <b-row>
        <b-col
          class="text-center border-t cursor-pointer border-e p-1"
          cols="6"
          @click="onItemClick"
        >
          <router-link to="/users/manage">
            <b-avatar
              size="3.5rem"
              variant="light-secondary"
            >
              <feather-icon
                icon="UserIcon"
                size="22"
              />
            </b-avatar>
            <h6 class="text-base font-weight-semibold mt-50 mb-0">
              Users
            </h6>
            <span class="text-sm text-secondary">Manage</span>
          </router-link>
        </b-col>
        <b-col
          class="text-center border-t cursor-pointer p-1"
          cols="6"
          @click="onItemClick"
        >
          <router-link to="/system-settings">
            <b-avatar
              size="3.5rem"
              variant="light-secondary"
            >
              <feather-icon
                icon="SettingsIcon"
                size="22"
              />
            </b-avatar>
            <h6 class="text-base font-weight-semibold mt-50 mb-0">
              Settings
            </h6>
            <span class="text-sm text-secondary">System Settings</span>
          </router-link>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          class="text-center border-t cursor-pointer border-e p-1"
          cols="6"
          @click="onItemClick"
        >
          <router-link to="/invitation-requests/manage">
            <b-avatar
              size="3.5rem"
              variant="light-secondary"
            >
              <feather-icon
                icon="ClockIcon"
                size="22"
              />
            </b-avatar>
            <h6 class="text-base font-weight-semibold mt-50 mb-0">
              Invitation Requests
            </h6>
            <span class="text-sm text-secondary">Manage</span>
          </router-link>
        </b-col>
        <b-col
          class="text-center border-t cursor-pointer p-1"
          cols="6"
          @click="onItemClick"
        >
          <router-link
            to="/shops/manage"
          >
            <b-avatar
              size="3.5rem"
              variant="light-secondary"
            >
              <feather-icon
                icon="ShoppingBagIcon"
                size="22"
              />
            </b-avatar>
            <h6 class="text-base font-weight-semibold mt-50 mb-0">
              Shops
            </h6>
            <span class="text-sm text-secondary">Manage</span>
          </router-link>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          class="text-center border-t cursor-pointer border-e p-1"
          cols="6"
          @click="onItemClick"
        >
          <router-link to="/contact-us/manage">
            <b-avatar
              size="3.5rem"
              variant="light-secondary"
            >
              <feather-icon
                icon="MailIcon"
                size="22"
              />
            </b-avatar>
            <h6 class="text-base font-weight-semibold mt-50 mb-0">
              Contact Us
            </h6>
            <span class="text-sm text-secondary">Manage</span>
          </router-link>
        </b-col>
        <b-col
          class="text-center border-t cursor-pointer border-e p-1"
          cols="6"
          @click="onItemClick"
        >
          <router-link to="/tools/forecast">
            <b-avatar
              size="3.5rem"
              variant="light-secondary"
            >
              <feather-icon
                icon="TrendingUpIcon"
                size="22"
              />
            </b-avatar>
            <h6 class="text-base font-weight-semibold mt-50 mb-0">
              Forecast
            </h6>
            <span class="text-sm text-secondary">Test Tool</span>
          </router-link>
        </b-col>
      </b-row>
    </b-card>

  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BRow, BCol, BAvatar, BCard,
} from 'bootstrap-vue'

export default {
  name: 'AdminMenu',
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,
    BNavItemDropdown,
  },
  methods: {
    onItemClick() {
      this.$refs.dropdown.hide()
    },
  },
}
</script>

<style lang="scss" scoped>
  .admin-menu {
    .card-body {
      padding: 1rem;
      padding-bottom: 0;
    }
  }
</style>
