<template>
  <div
    class="contact-us-form customizer d-none d-md-block"
    :class="{'open': isOpen}"
  >
    <!-- Header -->
    <div class="customizer-section d-flex justify-content-between align-items-center">
      <div>
        <div class="tw-flex tw-flex-row tw-justify-between tw-items-center">
          <h4 class="text-uppercase mb-0">
            Contact Us
          </h4>
          <div>
            <feather-icon
              class="tw-cursor-pointer"
              icon="XIcon"
              size="20"
              @click="onClose"
            />
          </div>
        </div>
        <small>We're have to help and answer any question you might have. We look forward to hearing from you.</small>
      </div>
    </div>
    <!-- Header -->

    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="ps-customizer-area scroll-area"
    >
      <DxValidationGroup
        ref="formValidator"
        class="tw-flex tw-flex-col tw-gap-8 tw-p-5"
      >
        <DxTextBox
          v-model="form.subject"
          validation-message-mode="always"
          placeholder="Subject"
        >
          <DxValidator
            ref="subjectValidator"
          >
            <DxRequiredRule message="Required field" />
          </DxValidator>
        </DxTextBox>
        <DxTextArea
          v-model="form.message"
          validation-message-mode="always"
          placeholder="What can we help you with?"
          height="200px"
        >
          <DxValidator ref="messageValidator">
            <DxRequiredRule message="Required field" />
          </DxValidator>
        </DxTextArea>
        <div>
          <DxButton
            type="default"
            text="Submit"
            @click="onSubmit"
          />
        </div>
      </DxValidationGroup>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { ref } from '@vue/composition-api'
import DxTextBox from 'devextreme-vue/text-box'
import { DxTextArea } from 'devextreme-vue/text-area'
import DxButton from 'devextreme-vue/button'
import { DxRequiredRule, DxValidator } from 'devextreme-vue/validator'
import { DxValidationGroup } from 'devextreme-vue/validation-group'
import EventBus from '@/utils/event-bus'

export default {
  components: {
    DxValidator,
    DxRequiredRule,
    DxButton,
    DxTextBox,
    DxTextArea,
    // 3rd party
    VuePerfectScrollbar,
    DxValidationGroup,
  },
  setup() {
    // Perfect Scrollbar
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const isOpen = ref(false)
    const form = ref({
      subject: null,
      message: null,
    })

    return {
      form,
      isOpen,
      // Perfect Scrollbar
      perfectScrollbarSettings,
    }
  },
  created() {
    EventBus.$on('app_contact_us_show', this.showForm)
  },
  destroyed() {
    EventBus.$off('app_contact_us_show', this.showForm)
  },
  methods: {
    onClose() {
      this.isOpen = false
      this.form = {
        subject: null,
        message: null,
      }
      this.$nextTick(() => {
        this.$refs.formValidator.instance.reset()
      })
    },
    showForm() {
      this.isOpen = true
    },
    onSubmit() {
      if (this.$refs.formValidator.instance.validate().isValid) {
        this.$store.dispatch('contactUs/sendMessage', this.form).then(res => {
          this.isOpen = false
          this.$bvToast.toast(res.message, {
            title: 'Success',
            toaster: 'b-toaster-top-center',
            variant: 'success',
            solid: true,
            appendToast: false,
          })
        }).catch(e => {
          this.$bvToast.toast(e.message, {
            title: 'Error',
            toaster: 'b-toaster-top-center',
            variant: 'danger',
            solid: true,
            appendToast: false,
          })
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.contact-us-form {
  ::v-deep .dx-invalid-message {
    .dx-overlay-content {
      background: none;
      color: #ea5455;
      padding: 0;
    }
  }
}

.customizer-section {
  padding: 1.5rem;
  border-bottom: 1px solid #ebe9f1;

  .dark-layout & {
    border-color: $theme-dark-border-color;
  }

  #skin-radio-group ::v-deep {
    .custom-control-inline {
      margin-right: 0.7rem;
    }
  }

  .form-group {
    margin-bottom: 1.5rem;;
    &:last-of-type {
      margin-bottom: 0;
    }
    ::v-deep legend {
      font-weight: 500;
    }
  }
}

.mark-active {
  box-shadow: 0 0 0 0.2rem rgba(38,143,255,.5);
}

.ps-customizer-area {
  height: calc(100% - 83px)
}
</style>
