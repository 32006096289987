<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 700 700"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g>
      <path
        transform="translate(-35, 70)"
        d="m378 42c-123.2 0-225.12 94.078-236.88 214.48h-33.602c-12.879 0-23.52 10.641-23.52 23.52s10.641 23.52 23.52 23.52h181.44c8.9609 0 16.801-5.0391 20.719-12.879l19.602-38.078 59.918 115.92c3.9219 7.8398 11.762 12.879 20.719 12.879 8.9609 0 16.801-5.0391 20.719-12.879l33.602-65.52h33.602c12.879 0 23.52-10.641 23.52-23.52s-10.641-23.52-23.52-23.52h-47.602c-8.9609 0-16.801 5.0391-20.719 12.879l-19.602 38.078-59.918-115.36c-7.8398-15.68-33.602-15.68-41.441 0l-33.602 65.52h-86.797c11.762-94.641 92.398-168 189.84-168 105.28 0 191.52 85.68 191.52 191.52s-86.238 190.96-191.52 190.96c-79.52 0-148.4-49.281-176.96-118.72h-49.84c30.801 95.758 120.96 165.2 226.8 165.2 131.04 0 238-106.96 238-238s-106.96-238-238-238z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    size: {
      type: Number,
      default: 28,
    },
  },
}
</script>

<style lang="scss" scoped>
.success {
  path {
    fill: #28c76f;
  }
}
.danger {
  path {
    fill: #ea5455;
  }
}
.warning {
  path {
    fill: #ff9f43
  }
}
.info {
  path {
    fill: #00cfe8;
  }
}
</style>
