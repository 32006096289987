<template>
  <b-nav-item-dropdown
    class="dropdown-impersonation mr-25"
    menu-class="dropdown-menu-media"
    right
    @show="isShown = true"
    @hidden="onHidden"
  >
    <template #button-content>
      <UserSecretIcon />
    </template>
    <DxList
      ref="list"
      class="p-1"
      :data-source="datasource"
      :height="300"
      :search-enabled="true"
      :active-state-enabled="false"
      :focus-state-enabled="false"
      search-mode="contains"
      :on-item-click="onItemClick"
    >
      <template #item="{ data: item }">
        <div class="d-flex flex-row">
          <div class="p-50">
            <b-avatar
              variant="light-primary"
              :src="item.avatar"
            >
              <template v-if="isEmpty(item.avatar)">
                {{ initials(item.name) }}
              </template>
            </b-avatar>
          </div>
          <div class="d-flex flex-column flex-1 p-50">
            <div class="tw-flex tw-flex-col" v-if="item.company_name">
              {{ item.company_name }}
              <small
                class="text-truncate"
                style="max-width: 185px"
              >{{ userName(item.name) }}</small>
            </div>
            <div v-else>
              {{ userName(item.name) }}
            </div>
            <small
              class="text-truncate tw-mt-1"
              style="max-width: 185px"
            >{{ item.email }}</small>
          </div>
          <div class="p-50 text-right">
            <b-badge>{{ item.role }}</b-badge>
          </div>
        </div>
      </template>
    </DxList>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BBadge,
  BAvatar,
} from 'bootstrap-vue'
import DxList from 'devextreme-vue/list'
import UserSecretIcon from '@/components/icons/UserSecretIcon.vue'
import userDatasource from '@/data/user.datasource'

export default {
  name: 'Impersonation',
  components: {
    UserSecretIcon,
    BNavItemDropdown,
    DxList,
    BBadge,
    BAvatar,
  },
  data() {
    return {
      isShown: false,
      datasource: userDatasource.getInstance(this.$store, true),
    }
  },
  methods: {
    userName(name) {
      if (!name) {
        return 'Shop Owner'
      }

      return name
    },
    initials(name) {
      if (!name) {
        return 'SO'
      }
      const parts = name.split(' ')
      return `${parts[0].substring(0, 1)}${parts[1].substring(0, 1)}`.toUpperCase()
    },
    onItemClick({ itemData }) {
      this.$store.dispatch('auth/impersonate', itemData.id).then(() => {
        this.$router.go()
      })
    },
    isEmpty(value) {
      return value === undefined || value === null || value.length === 0
    },
    onHidden() {
      this.$refs.list.instance.option('searchValue', '')
    },
  },
}
</script>

<style lang="scss">
  .dropdown-impersonation {
    .dropdown-menu.dropdown-menu-media {
      width: 25rem;
    }
  }
</style>
