<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex" />

    <b-navbar-nav class="nav align-items-center ml-auto">
      <DarkToggler />
      <SystemStatusDropdown />
      <Impersonation v-if="!isImpersonated && isAdmin" />
      <AdminMenu v-if="isAdmin" />
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
        :class="{ impersonated: isImpersonated }"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0" :title="fullname">
              {{ companyName }}
            </p>
            <span
              v-if="isAdmin"
              class="user-status"
            >Admin</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="avatar"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="onMyAccountClick"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>My Account</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item
          v-if="!isImpersonated"
          link-class="d-flex align-items-center"
          @click="onLogoutClick"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>

        <b-dropdown-item
          v-else
          link-class="d-flex align-items-center"
          @click="onStopImpersonation"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Stop Impersonation</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import SystemStatusDropdown from '@/components/SystemStatusDropdown.vue'
import Impersonation from '@/components/Impersonation.vue'
import AdminMenu from '@/components/AdminMenu.vue'

export default {
  components: {
    Impersonation,
    AdminMenu,
    SystemStatusDropdown,
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    fullname() {
      return this.$store.getters['auth/userFullName']()
    },
    companyName() {
      return this.$store.getters['auth/companyName']()
    },
    role() {
      return this.$store.getters['auth/isAdmin']() ? 'Admin' : ''
    },
    isAdmin() {
      return this.$store.getters['auth/isAdmin']()
    },
    avatar() {
      return this.$store.getters['auth/userAvatar']()
    },
    isImpersonated() {
      return this.$store.getters['auth/isImpersonated']
    },
  },
  methods: {
    onLogoutClick() {
      this.$store.dispatch('auth/logout').then(() => { this.$router.push({ name: 'auth-login' }) })
    },
    onStopImpersonation() {
      this.$store.dispatch('auth/stopImpersonation').then(() => {
        this.$router.go()
      })
    },
    onMyAccountClick() {
      if (this.$router.currentRoute.name === 'account-setting' && this.$router.currentRoute.params.activeTab === 'general') return
      this.$router.push({ name: 'account-setting', params: { activeTab: 'general' } })
    },
  },
}
</script>

<style lang="scss">
.dropdown-user {
  &.impersonated {
    .dropdown-menu.dropdown-menu-right.show {
      width: 16rem !important;
    }
  }
}
</style>
