<template>
  <b-nav-item-dropdown
    v-if="isSynchronized"
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <div
        v-if="isLoading"
        style="width: 28px; height: 28px"
      >
        <b-spinner
          small
          type="grow"
        />
      </div>
      <SystemStatusIcon
        v-else
        :class="statusColorClass"
      />
    </template>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- System Notifications -->
      <b-link
        v-for="(notification, index) in systemNotifications"
        :key="index"
      >
        <b-media>
          <template #aside>
            <b-avatar
              size="32"
              :variant="getIconVariant(notification.type)"
            >
              <feather-icon :icon="getIconByType(notification.type)" />
            </b-avatar>
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.title }}
            </span>
          </p>
          <small class="notification-text">{{ notification.subtitle }}</small>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BMedia, BLink, BAvatar, BSpinner,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import SystemStatusIcon from '@/components/icons/SystemStatusIcon.vue'
import store from '@/store'

export default {
  components: {
    SystemStatusIcon,
    BNavItemDropdown,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BSpinner,
  },
  data() {
    return {
      systemNotifications: [],
      isLoading: true,
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
    }
  },
  computed: {
    hasInfo() {
      return this.systemNotifications.filter(item => item.type === 'info').length
    },
    hasDanger() {
      return this.systemNotifications.filter(item => item.type === 'danger').length
    },
    hasWarning() {
      return this.systemNotifications.filter(item => item.type === 'warning').length
    },
    statusColorClass() {
      if (this.hasDanger) {
        return 'danger'
      }
      if (this.hasWarning) {
        return 'warning'
      }
      if (this.hasInfo) {
        return 'info'
      }
      return 'success'
    },
    isSynchronized() {
      return this.$store.getters['auth/isSynchronized']
    },
  },
  created() {
    store.dispatch('user/getShopStatus').then(({ items }) => {
      this.systemNotifications = items ?? []
    }).finally(() => {
      this.isLoading = false
    })
  },
  mounted() {
    setInterval(() => {
      if (!document.hidden) {
        store.dispatch('user/getShopStatus').then(({ items }) => {
          this.systemNotifications = items ?? []
        })
      }
    }, 300000)
  },
  methods: {
    getIconVariant(variant) {
      return `light-${variant}`
    },
    getIconByType(type) {
      switch (type) {
        case 'warning':
          return 'AlertTriangleIcon'
        case 'danger':
          return 'XIcon'
        case 'success':
          return 'CheckIcon'
        case 'AlertTriangleIcon':
        default:
          return 'CheckIcon'
      }
    },
  },
}
</script>

<style>

</style>
