export default [
  // {
  //   title: 'P&L',
  //   icon: 'ShoppingCartIcon',
  //   children: [
  //     {
  //       title: 'P&L Overview',
  //       route: 'p&l-overview',
  //     },
  //     {
  //       title: 'P&L Breakdown',
  //       route: 'p&l-breakdown',
  //     },
  //     {
  //       title: 'Other Transaction',
  //       route: 'p&l-transaction',
  //     },
  //   ],
  // },
  // {
  //   title: 'Orders / Sales',
  //   icon: 'ShoppingCartIcon',
  //   children: [
  //     {
  //       title: 'Orders by ASIN',
  //       route: 'orders-by-asin',
  //     },
  //     {
  //       title: 'Orders by Recency',
  //       route: 'orders-by-recency',
  //     },
  //     {
  //       title: 'Velocity / Trends',
  //       route: 'sales-velocity-trends',
  //     },
  //     {
  //       title: 'Forecasts',
  //       route: 'sales-forecasts',
  //     },
  //   ],
  // },
  // {
  //   title: 'Insights',
  //   icon: 'BarChart2Icon',
  //   children: [
  //     {
  //       title: 'Customer Insight',
  //     },
  //     {
  //       title: 'Product Reviews',
  //     },
  //     {
  //       title: 'Seller Reviews',
  //     },
  //     {
  //       title: 'Account Health',
  //     },
  //     {
  //       title: 'FBA Fees Changes',
  //     },
  //     {
  //       title: 'Inventory Issues',
  //     },
  //     {
  //       title: 'ASIN Traffic',
  //     },
  //     {
  //       title: 'Watch List',
  //     },
  //   ],
  // },
  // {
  //   title: 'Inventory / Product',
  //   icon: 'PackageIcon',
  // },
  // {
  //   title: 'Emails to Buyers',
  //   icon: 'MailIcon',
  // },
  // {
  //   title: 'Homepage',
  //   icon: 'TrelloIcon',
  // },
  // {
  //   title: 'Settings',
  //   icon: 'SettingsIcon',
  //   children: [
  //     {
  //       title: 'Notifications',
  //     },
  //     {
  //       title: 'Subscription Info',
  //     },
  //     {
  //       title: 'Invoices',
  //     },
  //   ],
  // },
  // {
  //   header: 'Old Menu',
  // },
  {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'TrelloIcon',
  },
  {
    header: 'Monitor',
  },
  {
    title: 'Sales',
    route: 'sales',
    icon: 'ShoppingCartIcon',
  },
  {
    title: 'Profit & Loss',
    route: 'profit_loss',
    icon: 'DollarSignIcon',
  },
  {
    title: 'Trends',
    route: 'trends',
    icon: 'TrendingUpIcon',
  },
  {
    title: 'Inventory',
    route: 'inventory',
    icon: 'AlignJustifyIcon',
  },
  {
    title: 'Inventory Xray',
    route: 'daily_inventory',
    icon: 'CalendarIcon',
  },
  {
    title: 'Sellers Activity',
    route: 'sellers',
    icon: 'ActivityIcon',
  },
  {
    title: 'Settings',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Products Configuration',
        route: 'products-configuration',
        icon: 'MinusIcon',
      },
      {
        title: 'Account & Billing',
        route: 'account-setting',
        icon: 'MinusIcon',
      },
    ],
  },
]
